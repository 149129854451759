// default config
var CONFIG = {
  APP_HOST: 'http://app.mashdrop.dev',
}

var getEnv = function() {
  switch (window.location.host) {
  case 'mashdrop.com':
    return 'production';
  case 'dev.mashdrop.com':
    return 'dev';
  default:
    return 'local';
  }
}

var loadConfig = function() {
  switch (getEnv()) {
  case 'dev':
    CONFIG.APP_HOST = 'http://app.dev.mashdrop.com'
    break;
  case 'production':
    CONFIG.APP_HOST = 'http://app.mashdrop.com'
    break;
  }
}

// redirect within the environment
var appRedir = function(path) {
  var url = CONFIG.APP_HOST + '/#' + path

  window.location = url;
}

// show and hide element
var toggleDisplay = function(id) {
  var e = document.getElementById(id);
  if (e.style.display == '') {
    e.style.display = 'block';

    return;
  }

  e.style.display = '';
}

var toggleNav = function(e) {
  var on = 'navicon';
  var off = 'times';

  toggleDisplay('nav');

  // check if navicon is there
  if (e.className.indexOf(on) !== -1) {
    e.className = e.className.replace(on, off);

    return;
  }

  e.className = e.className.replace(off, on);
}

var analytics = function() {
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-53683442-3', 'auto');
  ga('send', 'pageview', {
    'title': 'Website',
  });
}

// bootstrap
loadConfig();
analytics();
Barba.Pjax.start();
